html {
    scroll-behavior: smooth;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1;
}