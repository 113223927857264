@import '../../styles/variables.scss';

.banner {
    background: $primaryColor;
    padding: 20px 0;

    .fb-messenger {
        
        text-align: right;
        margin: auto;

        .fab {
            font-size: 40px;
        }
    }

    .email-link {
        color: black;
        text-decoration: underline;
    }
}