@import '../styles/variables.scss';

.login-page {
    margin: 100px 0;
    flex-direction: column;
    text-align: center;

    .login-title {
        border-bottom: 1px solid black;
        width: 280px;
        margin: auto;
        padding-bottom: 10px;
    }

    .login-button {
        padding-top: 20px;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;

        > * {
            flex: 1;
        }

        .login-title {
            padding: 40px 20px 40px 0;
            text-align: right;
            border: 0;
            border-right: 1px solid black;
        }

        .login-button {
            padding-top: 0;
            padding-left: 20px;
            margin: auto;
            text-align: left;
        }
    }

    
}