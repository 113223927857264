@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 80px; }
  @media (max-width: 991.98px) {
    nav .user-button {
      margin-left: 0 !important; }
    nav .user-name {
      vertical-align: middle;
      text-align: left; } }
  nav .navbar-toggler {
    border: 0; }
  nav .nav-link {
    align-items: center;
    color: black !important;
    display: flex;
    margin-right: 15px; }
    nav .nav-link .fas {
      opacity: 0.7;
      font-size: 25px;
      margin-right: 8px; }
    nav .nav-link .fa-user-circle {
      font-size: 30px; }
  nav .navbar-brand img {
    height: 50px; }
  nav .navbar-toggler {
    border-radius: 0; }
  nav .user-button {
    margin: auto;
    display: flex; }
  nav .user-name {
    margin: auto;
    padding-right: 10px; }
  nav .user-image {
    width: 35px;
    border-radius: 50%;
    border: black 1px solid; }

.submit-success {
  margin-top: 40px;
  text-align: center; }
  .submit-success .btn:first-child {
    margin-right: 10px; }

.add-initiative {
  margin-bottom: 20px; }

.jumbotron {
  background: no-repeat center center;
  background-size: cover;
  color: white;
  height: calc(80vh - 80px);
  margin: 0; }
  .jumbotron .container {
    padding: 40px 60px;
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0.4); }

.instructions .logo {
  text-align: center; }
  .instructions .logo img {
    width: 150px; }

.instructions .title {
  text-align: center;
  margin: 25px 0; }

.instructions-card {
  margin-bottom: 20px; }
  .instructions-card .instructions-card-title {
    background: #159BA7;
    color: white;
    padding: 10px;
    font-size: 2rem; }
  .instructions-card .instructions-card-body {
    background: #F2F2F2; }
    .instructions-card .instructions-card-body .instructions-card-step {
      display: flex;
      padding: 20px;
      align-items: center; }
      .instructions-card .instructions-card-body .instructions-card-step > * {
        margin-right: 20px; }
      .instructions-card .instructions-card-body .instructions-card-step .number-box {
        padding: auto;
        height: 40px;
        width: 40px;
        background: #969696;
        opacity: 0.5;
        border-radius: 50%; }
        .instructions-card .instructions-card-body .instructions-card-step .number-box .number {
          font-size: 24px;
          text-align: center;
          margin-top: 2px;
          color: #F2F2F2; }
  .instructions-card .instructions-card-footer {
    padding: 20px;
    background: rgba(125, 125, 125, 0.5); }
  .instructions-card .fab, .instructions-card .fas {
    text-align: center;
    font-size: 45px;
    opacity: 0.5;
    width: 50px; }

.login-page {
  margin: 100px 0;
  flex-direction: column;
  text-align: center; }
  .login-page .login-title {
    border-bottom: 1px solid black;
    width: 280px;
    margin: auto;
    padding-bottom: 10px; }
  .login-page .login-button {
    padding-top: 20px; }
  @media (min-width: 768px) {
    .login-page {
      flex-direction: row; }
      .login-page > * {
        flex: 1 1; }
      .login-page .login-title {
        padding: 40px 20px 40px 0;
        text-align: right;
        border: 0;
        border-right: 1px solid black; }
      .login-page .login-button {
        padding-top: 0;
        padding-left: 20px;
        margin: auto;
        text-align: left; } }

.user-image {
  margin: 20px 0;
  border-radius: 50%;
  width: 200px;
  border: 1px solid black; }

.user-info {
  padding: 20px; }

.initiative-card-img-border {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center; }

.initiative-card .card-title {
  font-weight: bold; }

.initiative-card .card-text .read-more__button {
  display: inline;
  background-color: transparent;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  color: #007f8c; }

.initiative-card .card-text .read-more__button:hover {
  text-decoration: underline; }

.initiative-card .card-text .read-more__text--hide {
  max-height: 0;
  font-size: 0;
  opacity: 0; }

.initiative-card .card-text .read-more__text--show {
  max-height: 10em;
  opacity: 1;
  font-size: inherit; }

.initiative-card .card-text .read-more__text--remaining {
  -webkit-transition: opacity 240ms ease;
  transition: opacity 240ms ease; }

.initiative-list-title {
  padding: 20px; }

.footer {
  background: rgba(0, 0, 0, 0.7);
  color: #e4e4e4;
  font-size: 14px;
  padding: 30px; }
  .footer .row {
    display: flex;
    align-items: center; }
  .footer .logo {
    text-align: right; }
  .footer img {
    height: 60px; }

.banner {
  background: #ebebf1;
  padding: 20px 0; }
  .banner .fb-messenger {
    text-align: right;
    margin: auto; }
    .banner .fb-messenger .fab {
      font-size: 40px; }
  .banner .email-link {
    color: black;
    text-decoration: underline; }

html {
  scroll-behavior: smooth; }

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

main {
  flex: 1 1; }

