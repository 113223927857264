// Font
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
$fontFamiliy: 'Roboto', sans-serif;

// Colors
$primaryColor: #ebebf1;
$accentColorBlue: #159ba7;
$accentColorDarkGray: #646464;
$accentColorGray: #7d7d7d;
$accentColorLightGray: #969696;

// Sizes
$navbarHeight: 80px;